/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-30 10:17:43
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-10-15 15:53:40
 */
import config from './config/teacher'
import axios from '@/utils/request'

export const getTeacherList = params => axios.post(config.teacherList, params) //老师预约列表
export const getTeacherTypeSelect = params => axios.post(config.teacherTypeSelect, params) //老师分类下拉
export const getTeacherTagSelect = params => axios.post(config.teacherTagSelect, params) //老师专长下拉
export const geteTacherDetail = params => axios.post(config.teacherDetail, params) //老师详情
export const geteCourseDetail = params => axios.post(config.courseDetail, params) //线上课程详情
export const geteOrganizationdetail = params => axios.post(config.organizationdetail, params) //机构详情
export const getAddAboutNews = params => axios.post(config.aboutNews, params) //新建预约
export const getFileUpload = (params, callback) =>
  axios.post(`/third/api/huaWeiUpload/upload/file`, params, callback) //上传附件
export const getMyAboutList = params => axios.post(config.myAboutList, params) //我的预约列表
export const getMySearchAbout = params => axios.post(config.searchAbout, params) //我的预约编辑查询
export const getMyEditAbout = params => axios.post(config.editAbout, params) //我的预约编辑
export const getDelAbout = params => axios.post(config.delAbout, params) //取消预约
export const getTeacherSelectList = params => axios.post(config.teacherSelectList, params) //老師下拉列表
export const getMyAboutDetail = params => axios.post(config.myAboutDetail, params) //预约详情
export const searchComment = params => axios.post(config.searchComment, params) //评价查询
export const submitComment = params => axios.post(config.submitComment, params) //评价提交
export const schoolDetail = params => axios.post(config.schoolDetail, params) //学堂详情
export const schoolClass = params => axios.post(config.schoolClass, params) //学堂班级
export const schoolCourse = params => axios.post(config.schoolCourse, params) //学堂课程
export const schoolTeacher = params => axios.post(config.schoolTeacher, params) //学堂讲师
export const teacherType = params => axios.post(config.teacherType, params) //预约讲师列表讲师分类
export const teacherNewList = params => axios.post(config.teacherNewList, params) //老师预约列表
export const excellentTeachers = params => axios.post(config.excellentTeachers, params) //优秀老师
export const offCourse = params => axios.post(config.offCourse, params) //线下课程列表
export const witnessList = params => axios.post(config.witnessList, params) //授课见证列表
export const courseVideo = params => axios.post(config.courseVideo, params) //授课视频列表
export const videoSearch = params => axios.post(config.videoSearch, params) //vid查视频
export const videoBrowse = params => axios.post(config.videoBrowse, params) //视频瀏覽量
export const teacherCustomer = params => axios.post(config.teacherCustomer, params) //讲师评价
export const pushOffCourse = params => axios.post(config.pushOffCourse, params) //推荐线下课程列表
export const pushOnCourse = params => axios.post(config.pushOnCourse, params) //推荐线上课程列表
export const pushWitnessList = params => axios.post(config.pushWitnessList, params) //推荐授课见证列表
export const pushVideo = params => axios.post(config.pushVideo, params) //推荐授课视频列表
export const offCourseDetail = params => axios.post(config.offCourseDetail, params) //线下课程详情
export const witnessDetail = params => axios.post(config.witnessDetail, params) //见证详情
export const internalTrainer = params => axios.post(config.internalTrainer, params) //内训师列表
export const saveTrainer = params => axios.post(config.saveTrainer, params) //新增内训师
export const trainerStudentInfo = params => axios.post(config.trainerStudentInfo, params) //根据企业id查询企业下学员信息
export const findByIdTrainer = params => axios.post(config.findByIdTrainer, params) //根据企业id查询内训师详情
export const updataTrainer = params => axios.post(config.updataTrainer, params) //修改内训师
export const updateCourseFee = params => axios.post(config.updateCourseFee, params) //编辑薪酬
export const trainerCourseInfo = params => axios.post(config.trainerCourseInfo, params) //主讲课程
export const teacherHomePage = params => axios.post(config.teacherHomePage, params) //预约讲师首页列表
export const teacherHomePagePrev = params => axios.post(config.teacherHomePagePrev, params) //预约讲师首页列表
export const teacherHomePageNext = params => axios.post(config.teacherHomePageNext, params) //预约讲师首页列表
export const teachermajor = params => axios.post(config.teachermajor, params) //预约讲师首页分类
export const teacherBanner = params => axios.post(config.teacherBanner, params) //预约讲师首页轮播
export const deleteTrainer = params => axios.post(config.deleteTrainer, params) //删除内训师
export const tableTrainerData = params => axios.post(config.tableTrainerData, params) //统计报表
export const TrainerExport = params =>
  axios.post(config.TrainerExport, params, { responseType: 'blob' }) //导出报表
export const TrainerTeachingDetail = params => axios.post(config.TrainerTeachingDetail, params) //授课明细
export const TrainerCoursedetail = params => axios.post(config.TrainerCoursedetail, params) //内训师课程详情
export const trainerCourseSections = params => axios.post(config.trainerCourseSections, params) //课程章节
export const trainerCourseattachListRespList = params =>
  axios.post(config.trainerCourseattachListRespList, params) //课件资料
export const trainerdeptEndPoint = params => axios.post(config.trainerdeptEndPoint, params) //筛选部门
export const teacherPosterQuery = params => axios.post(config.teacherPosterQuery, params) //海报列表
export const teacherPosterTemplateQuery = params =>
  axios.post(config.teacherPosterTemplateQuery, params) //海报模板列表
export const teacherPosterInviteCode = params => axios.post(config.teacherPosterInviteCode, params) //获取预约二位吗
export const teacherPosterCreate = params => axios.post(config.teacherPosterCreate, params) // 新建海报
export const teacherPosterUpdate = params => axios.post(config.teacherPosterUpdate, params) // 编辑海报
export const teacherPosterDelete = params => axios.post(config.teacherPosterDelete, params) // 删除海报
export const reserveDetailSave = params => axios.post(config.reserveDetailSave, params) //添加签到场次
export const reserveDetailPage = params => axios.post(config.reserveDetailPage, params) //查询签到场次
export const reserveDetailEdit = params => axios.post(config.reserveDetailEdit, params) //编辑签到场次
export const reserveDetailDelete = params => axios.post(config.reserveDetailDelete, params) //删除签到场次
export const reserveDetailSort = params => axios.post(config.reserveDetailSort, params) //保存签到场次
export const reservePage = params => axios.post(config.reservePage, params) //分页查询报名列表
export const reserveCount = params => axios.post(config.reserveCount, params) //当前预约下的报名总人数
export const reserveExport = params =>
  axios.post(config.reserveExport, params, { responseType: 'blob' }) //报名列表导出
export const reserveCommentQrcord = params => axios.post(config.reserveCommentQrcord, params) //评价二维码
export const SessionList = params => axios.post(config.SessionList, params) //签到场次选择数组
export const signInPage = params => axios.post(config.signInPage, params) //分页查询签到列表
export const signListexport = params =>
  axios.post(config.signListexport, params, { responseType: 'blob' }) //报名列表导出
export const signInStatistic = params => axios.post(config.signInStatistic, params) //分页查询签到列表
export const editTrainInfo = params => axios.post(config.editTrainInfo, params) //编辑培训信息
export const getTrainInfo = params => axios.post(config.getTrainInfo, params) //获取培训信息
