/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-30 10:17:43
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-10-15 15:53:48
 */
export default {
  teacherList: '/course/manage/teacher/query/list',
  teacherTypeSelect: '/course/manage/teacher/query/selectlist/teachermajor',
  teacherTagSelect: '/course/manage/teacher/query/selectlist/teachertag',
  teacherDetail: '/course/manage/teacher/query',
  courseDetail: '/course/manage/teacher/query/onsale/courses/teacher',
  organizationdetail: '/course/manage/teacher/query/organizationdetail',
  aboutNews: '/course/manage/teacher/reserve/create',
  myAboutList: '/course/manage/teacher/reserve/query/list',
  searchAbout: '/course/manage/teacher/reserve/query',
  editAbout: '/course/manage/teacher/reserve/edit',
  delAbout: '/course/manage/teacher/reserve/cancel',
  teacherSelectList: '/course/manage/teacher/selectlist',
  myAboutDetail: '/course/manage/teacher/reserve/query/progress/rate',
  searchComment: '/course/manage/teacher/query/comment',
  submitComment: '/course/manage/teacher/submit/comment',
  schoolDetail: '/course/manage/teacher/query/org/info',
  schoolClass: '/course/manage/teacher/query/org/classes',
  schoolCourse: '/course/manage/teacher/query/org/courses',
  schoolTeacher: '/course/manage/teacher/query/org/teachers',
  teacherType: '/course/manage/teacher/query/teacher/majors',
  teacherNewList: '/course/manage/teacher/query/page',
  excellentTeachers: '/course/manage/teacher/query/excellent/teachers',
  offCourse: '/course/manage/teacher/query/teacher/offline/courses/page',
  witnessList: '/course/manage/teacher/query/teacher/teaching/witness/page',
  courseVideo: '/course/manage/teacher/query/teacher/teaching/video/page',
  videoSearch: '/course/video/play/info',
  videoBrowse: '/course/manage/teacher/browse/teaching/video',
  teacherCustomer: '/course/manage/teacher/query/teacher/customer/reviews/page',
  pushOffCourse: '/course/manage/teacher/query/recommend/offline/courses',
  pushOnCourse: '/course/manage/teacher/query/recommend/online/courses',
  pushWitnessList: '/course/manage/teacher/query/recommend/teaching/witness',
  pushVideo: '/course/manage/teacher/query/recommend/teaching/video',
  offCourseDetail: '/course/manage/teacher/query/offline/courses/detail',
  witnessDetail: '/course/manage/teacher/browse/teaching/witness',
  internalTrainer: '/course/identify/internalTrainer/toPage',
  saveTrainer: '/course/identify/internalTrainer/save',
  trainerStudentInfo: '/course/identify/internalTrainer/getStudentInfo',
  findByIdTrainer: '/course/identify/internalTrainer/findById',
  updataTrainer: '/course/identify/internalTrainer/update',
  updateCourseFee: '/course/identify/internalTrainer/updateCourseFee',
  trainerCourseInfo: '/course/identify/internalTrainer/getCourseInfo',
  teacherHomePage: '/course/manage/teacher/query/home/page',
  teacherHomePageNext: '/course/manage/teacher/query/home/teacher/list',
  teachermajor: '/course/manage/teacher/query/selectlist/teachermajor',
  teacherBanner: '/course/manage/teacher/query/home/banners',
  deleteTrainer: '/course/identify/internalTrainer/delete',
  tableTrainerData: '/course/identify/internalTrainer/statsInternalTrainerInfo',
  TrainerExport: '/course/identify/internalTrainer/StatsInternalTrainerExport',
  TrainerTeachingDetail: '/course/identify/internalTrainer/internalTrainerTeachingDetail',
  TrainerCoursedetail: '/course/identify/internalTrainer/course',
  trainerCourseSections: '/course/identify/internalTrainer/courses/sections',
  trainerCourseattachListRespList: '/course/identify/internalTrainer/courses/attachListRespList',
  trainerdeptEndPoint: '/course/identify/internalTrainer/depts/deptEndPoint',
  teacherPosterQuery: '/course/manage/reserve/poster/query', // 海报列表
  teacherPosterTemplateQuery: '/course/manage/reserve/poster/template/query', // 海报模板列表
  teacherPosterInviteCode: '/course/manage/reserve/poster/inviteCode', // 获取预约二维码
  teacherPosterCreate: '/course/manage/reserve/poster/create', // 新建海报
  teacherPosterUpdate: '/course/manage/reserve/poster/update', // 编辑海报
  teacherPosterDelete: '/course/manage/reserve/poster/delete', // 删除海报
  reserveDetailSave: '/course/manage/reserve/detail/save',
  reserveDetailPage: '/course/manage/reserve/detail/page',
  reserveDetailEdit: '/course/manage/reserve/detail/edit',
  reserveDetailDelete: '/course/manage/reserve/detail/delete',
  reserveDetailSort: '/course/manage/reserve/detail/sort',
  reservePage: '/course/manage/reserve/registration/page',
  reserveCount: '/course/manage/reserve/registration/registered/count',
  reserveExport: '/course/manage/reserve/registration/excel/export',
  reserveCommentQrcord: '/course/manage/teacher/query/reserve/comment/qrcord',
  SessionList: '/course/manage/reserve/punch/reserve/detail/select/list',
  signInPage: '/course/manage/reserve/punch/page',
  signListexport: '/course/manage/reserve/punch/excel/export',
  signInStatistic: '/course/manage/reserve/punch/statistic',
  editTrainInfo: '/course/manage/teacher/edit/train/info',
  getTrainInfo: '/course/manage/teacher/get/train/info',
}
