<template>
  <div class="class-list">
    <table-list
      title="内训师列表"
      :loading="loading"
      :data="TrainerList"
      :search-form="searchForm(this)"
      :columns="columns(this)"
      :operates="operates(this)"
      :pager="pager"
      :btns="btns(this)"
      :options="{ selection: true, operatesWidth: 200, fixScrollTable: true }"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
    <el-dialog title="编辑薪酬" :visible.sync="dialogFormVisible" width="20%">
      <div class="dialogFee">
        <el-input
          v-model.trim="CourseFee"
          placeholder="请输入金额"
          maxlength="7"
          class="courseFee"
          @keyup.native="CourseFee = inputChange(CourseFee)"
        ></el-input>
        <span>元/课时</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="updataCourseFee">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '统计报表',
    method: _this.handleTable,
  },
  {
    label: '添加内训师',
    method: _this.handleTrainerAdd,
  },
  {
    label: '删除',
    method: _this.handleDelete,
    type: 'danger',
  },
]
// 搜索框配置
const searchForm = _this => [
  {
    label: '内训师姓名',
    prop: 'userName',
    width: 150,
  },
  {
    label: '手机号',
    prop: 'phone',
    width: 120,
  },
  {
    label: '部门筛选',
    type: 'select',
    width: 120,
    prop: 'classId',
    children: _this.deptEndPointList.map(item => ({ value: item.deptId, label: item.deptName })),
  },
  {
    label: '讲师所属',
    type: 'select',
    width: 120,
    prop: 'teacherType',
    children: [
      { value: 1, label: '内部讲师' },
      { value: 2, label: '外聘讲师' },
    ],
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'userName',
    minWidth: 120,
    label: '内训师名称',
  },
  {
    prop: 'phone',
    minWidth: 120,
    label: '手机号码',
  },
  {
    prop: 'className',
    minWidth: 120,
    label: '所属部门',
  },
  {
    prop: 'teacherTypeShow',
    minWidth: 100,
    label: '内训师所属',
  },
  {
    prop: 'itLevelShow',
    minWidth: 100,
    label: '内训师等级',
  },
  {
    prop: 'courseFee',
    minWidth: 100,
    label: '课酬（元/课时）',
    align: 'right',
    formatter: row => {
      return row.courseFee ? '￥' + row.courseFee : ''
    },
  },
  {
    prop: 'createTime',
    minWidth: 160,
    label: '创建时间',
    sortable: 'sortable',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '编辑',
    method: _this.handleEdit,
  },
  {
    label: '设置课酬',
    method: _this.handlecourseFee,
  },
  {
    label: '详情',
    method: _this.handleTrainerInfo,
  },
]
import TableList from '@/components/TableList'
import { internalTrainer, updateCourseFee, deleteTrainer, trainerdeptEndPoint } from '@/api/teacher'
import to from 'await-to'
export default {
  name: 'Trainer',
  components: {
    TableList,
  },
  data() {
    return {
      searchForm,
      btns,
      columns,
      operates,
      loading: false,
      TrainerList: [],
      selectTrainer: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
      dialogFormVisible: false,
      CourseFee: null,
      dialogForm: {
        id: 0,
        courseFee: 0,
      },
      deptEndPointList: [],
    }
  },
  mounted() {
    this.gettrainerdeptEndPointList()
    this.getTrainer()
  },
  methods: {
    async getTrainer() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(internalTrainer({ current, size, ...this.queryParams }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.pager.total = res.data.total
      this.TrainerList = res.data.list
    },
    //筛选部门接口数据
    async gettrainerdeptEndPointList() {
      const [res, err] = await to(trainerdeptEndPoint({}))
      if (err) return this.$message.warning(err.msg)
      this.deptEndPointList = res.data
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getTrainer()
    },
    //统计报表
    handleTable() {
      this.$router.push({ path: '/teacher/trainerTable' })
    },
    //添加内训师
    handleTrainerAdd() {
      this.$router.push({ path: '/teacher/trainerAdd' })
    },
    // 删除
    async handleDelete() {
      if (!this.selectTrainer.length) return this.$message.warning('请先选择要删除的内训师')
      let trainerIds = []
      this.selectTrainer.map(item => trainerIds.push(item.id))
      this.$confirm('确认删除内训师吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(deleteTrainer({ ids: trainerIds }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getTrainer()
      })
    },
    //编辑
    handleEdit(row) {
      this.$router.push({
        path: '/teacher/trainerAdd',
        query: { id: row.id },
      })
    },
    //设置课酬
    handlecourseFee(row) {
      this.dialogFormVisible = true
      this.CourseFee = row.courseFee
      this.dialogForm.id = row.id
    },
    //效验输入框
    inputChange(num) {
      let str = num.toString()
      var len1 = str.substr(0, 1)
      var len2 = str.substr(1, 1)
      //如果第一位是0，第二位不是点，就用数字把点替换掉
      if (str.length > 1 && len1 == 0 && len2 != '.') {
        str = str.substr(1, 1)
      }
      if (len1 == '.') {
        str = ''
      }
      //限制只能输入一个小数点
      if (str.indexOf('.') != -1) {
        var str_ = str.substr(str.indexOf('.') + 1)
        if (str_.indexOf('.') != -1) {
          str = str.substr(0, str.indexOf('.') + str_.indexOf('.') + 1)
        }
        if (str_.length > 2) {
          this.$message.warning(`金额小数点后只能输入两位，请正确输入！`)
          return (str = '')
        }
      }
      //正则替换
      str = str.replace(/[^\d.]/g, '') // 保留数字和小数点
      return str
    },
    //编辑薪酬
    async updataCourseFee() {
      if (this.CourseFee == null) {
        this.$message.warning('请输入薪酬金额')
        return
      }
      this.dialogForm.courseFee = this.CourseFee
      const [, err] = await to(updateCourseFee(this.dialogForm))
      this.dialogFormVisible = false
      this.$message.success('设置成功')
      this.getTrainer()
      if (err) return this.$message.warning(err.msg)
    },
    //详情
    handleTrainerInfo(row) {
      this.$router.push({
        path: '/teacher/trainerInfo',
        query: { id: row.id, userId: row.userId },
      })
    },
    // 多选
    handleSelectionChange(val) {
      this.selectTrainer = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getTrainer()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getTrainer()
    },
  },
}
</script>
<style scoped>
.class-list >>> .el-dialog__body {
  padding: 20px 20px;
  display: flex;
  justify-content: center;
}
.dialogFee {
  display: flex;
}
.courseFee {
  width: 150px;
  height: 40px;
}
.dialogFee span {
  display: block;
  margin-left: 10px;
  margin-top: 5px;
  font-size: 16px;
}
</style>
